import { graphql } from 'gatsby';
import { Box, Grommet, Text, Anchor, Heading, Grid, Carousel } from 'grommet';
import Helmet from 'react-helmet';

import * as React from 'react';

import Layout from '../components/Layout';

const NotFoundPage = ({ data }) => {

  const siteTitle = data.site.siteMetadata.title;
  const siteDescription = data.site.siteMetadata.description;

  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={`${siteTitle} - Page Not Found`}>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500" />
      </Helmet>

      <main>
        <Box
          pad='large'
          align='center'
          flex
          background='white'
          margin={{ vertical: 'small' }}
          style={{ minHeight: '500px' }}>
          <Heading level='1'>NOT FOUND</Heading>
          <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>

        </Box>
      </main>

    </Layout>
  )
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
